.StripeElement {
//   box-sizing: border-box;
// 
//   height: 40px;
// 
//   padding: 10px 12px;
// 
//   border: 1px solid transparent;
//   border-radius: 4px;
//   background-color: white;
// 
//   box-shadow: 0 1px 3px 0 #e6ebf1;
//   -webkit-transition: box-shadow 150ms ease;
//   transition: box-shadow 150ms ease;

  @apply shadow-sm focus:ring-brand-blue-500 focus:border-brand-blue-500 block w-full sm:text-sm border-gray-300 bg-gray-200 rounded-md my-2 p-2;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}