/* Tailwind CSS */
@import "../stylesheets/tailwind";

/* Vendors */
@import "../stylesheets/vendors";

/* Style */
// @import "../stylesheets/fonts";
@import "../stylesheets/application-style";
@import "../stylesheets/new-new-style";

/* Components */
@import "../stylesheets/components/badges";
@import "../stylesheets/components/buttons";
@import "../stylesheets/components/filters";
@import "../stylesheets/components/stripe";

/* Icons */
@import "../stylesheets/custom-icons";

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@500;600&family=Inter:wght@400;600&display=swap');

@font-face {
  font-family: "DinoProBold";
  src: url('../fonts/DINNextLTPro-Bold.otf');
}

@font-face {
  font-family: "HomemadeApple";
  src: url('../fonts/HomemadeApple-Regular.ttf');
}

.autoComplete_wrapper {
  display: block;

  > input {
    width: 100%;
    height: auto;
    border: 1px solid lightgray;
    background-color: #FFF;
    color: #212121;

    &::placeholder {
      color: gray;
    }

    &::-ms-clear,
    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-cancel-button,
    &::-webkit-search-decoration,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }

  > ul {
    > li.autoComplete_no_result:hover {
      cursor: default;
      background-color: rgba(255, 255, 255, 1);
    }
  }
}

body {
  --banner-height: 64px;
  --banner-link-height: 24px;
  --header-height: 48px;
  --searchbar-height: 56px;
  --header-margin-top: 0;
}

@media (min-width: 768px) {
  body {
    --banner-height: 40px;
  }
}

@media (min-width: 831px) {
  body {
    --searchbar-height: 75px;
  }
}

@media (min-width: 1080px) {
  body {
    --header-height: 88px;
  }
}

body {
  &.unscrollable {
    overflow: hidden;
  }
}

body {
  &.with-header {
    --header-margin-top: var(--header-height);
  }

  &.with-searchbar {
    --header-margin-top: calc(var(--header-height) + var(--searchbar-height));

    &.with-banner {
      --header-margin-top: calc(var(--banner-height) + var(--header-height) + var(--searchbar-height));
    }

    &.with-banner-link {
      --header-margin-top: calc(var(--banner-height) + var(--banner-link-height) + var(--header-height) + var(--searchbar-height));
    }
  }

  &.with-banner {
    --header-margin-top: calc(var(--banner-height) + var(--header-height));
  }

  &.with-banner-link {
    --header-margin-top: calc(var(--banner-height) + var(--banner-link-height) + var(--header-height));
  }
}

body {
  margin-top: var(--header-margin-top);

  .margin-faq {
    top: calc(var(--header-margin-top) + 10px);
  }

  .scroll-margin-faq, #show-reviews {
    scroll-margin-top: calc(var(--header-margin-top) + 10px);
  }
}

body {
  .christmas_main {
    background-image: url("../../assets/images/events/stars_bg.png");
    background-size: contain;
  }

  .christmas_container {
    background-color: #F3F4F6;
  }
}

.text-new-brand-red-700 {
  color: #BC3F3F;
}

.text-new-brand-red-400 {
  color: #CB6363;
}

.label-checker {
  display: grid;
  grid-template-columns: 1fr 2fr 7fr 2fr;
  grid-template-rows: 1fr;
  grid-column-gap: 0px;
  grid-row-gap: 4px;
}

.radio-selector {
  grid-area: 1 / 1 / 2 / 2;
  margin-left: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.checked-seats {
  grid-area: 1 / 2 / 2 / 3;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-left: 1px solid lightgray;
  border-radius: 4px 0 0 4px;
  text-align: center;
}

.checked-deducted {
  grid-area: 1 / 3 / 2 / 4;
  text-align: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
}

.checked-free {
  grid-area: 1 / 4 / 2 / 5;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  border-top: 1px solid lightgray;
  border-bottom: 1px solid lightgray;
  border-right: 1px solid lightgray;
  border-radius: 0 4px 4px 0;

}

.radio-btn-checker:checked ~ span {
  border-color: #0678c0;
  background-color: #0679c010;
  color: #0678c0;
}

@media (max-width: 400px) {
  .label-checker {
    display: grid;
    grid-template-columns: 1fr 2fr 4fr 2fr;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 4px;
  }

  .checked-deducted {
    padding-top: 6px;
    padding-bottom: 6px;
  }
}

.swiper-slide {
  height: auto;
}
