.hero-text {
  font-weight: 500;
  font-size: 48px;
  color: #0A4E9B;
  font-family: 'EB Garamond', serif;
}

.hero-button {
  font-family: 'Inter', sans-serif;
  font-weight: 600;
  display: inline-block;
  padding: 12px 25px !important;
  border-radius: 4px;
  box-sizing: border-box;
  text-decoration: none;
  text-align: center;
  white-space: pre-wrap;
  font-size: 16px !important;
  position: relative;
  background-image: linear-gradient(45deg, #0EADE9 50%, rgba(0, 0, 0, 0) 50%);
  background-repeat: no-repeat;
  background-size: 200% 75px;
  background-position: 0 100%;
  box-shadow: none;
}

.trust {
  margin-top: 40px;

  .trust-header {
    font-family: 'EB Garamond', serif;
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 40px;
  }

  .trust-grid {
    display: grid;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    justify-content: center;
    align-items: center;
    grid-gap: 10px;
  }

  .line-1 {
    grid-template-columns: repeat(9, 1fr);
  }

  .line-2 {
    grid-template-columns: repeat(8, 1fr);
  }
}

.contact-header {
  margin-top: 24px;
  font-family: 'EB Garamond';
  font-size: 48px;
  div {
    text-align: center;
  }
}

.contact {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;

  .portrait {
    width: 300px;
    margin-left: auto;
    margin-right: auto;

    img {
      border-radius: 150%;
    }
  }

  p {
    margin-top: 50px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: 400;
    font-size: 18px;
  }

  form {
    width: 60%;
  }

  .pocket-mystery {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2px;

    img {
      display: inline-block;
      height: 18px;
      width: auto;
    }

    img:nth-child(4) {
      height: 11px;
    }
  }

  .mystery-pocket {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;

    img {
      width: auto;
      height: 14px;
      display: inline-block;
      margin-right: 5px;
    }
  }
}

@media screen and (max-width: 768px) {
  .static_pages-offers_enterprise {
    .hero-button {
      padding: 12px 20px !important;
    }
  }
}

.static_pages-offers_enterprise {
  .hero-background {
    background-color: #CFEFFAC9;
    border-radius: 8px;
  }

  .hero-text-highlight {
    color: #1F9AAB;
  }

  .icon-color {
    color: rgba(10, 78, 155);
    font-size: 75px;
    margin-bottom: 10px;
    display: block;
  }

  .cards {
    margin: 50px 0;
    display: flex;
    justify-content: center;
  }

  .card {
    text-align: center;
    justify-content: space-around;
    padding: 10px;
    margin: 0 auto;
    max-width: 330px;

    .card-image {
      width: 20px;
      height: 20px;
    }

    p {
      font-size: 14px;
      font-weight: 400;
      font-family: 'Inter', sans-serif;
      color: black;
    }

    hr {
      display: inline-block;
      background-color: black;
      width: 256px;
      height: 2px;
      border-top-width: 0;
      margin: 8px 0;
    }

    img,
    svg {
      display: inline-block;
      margin-bottom: 5px;
    }

    .inter-bold {
      font-family: 'Inter', sans-serif;
      font-weight: 700;
    }
  }

}

@media screen and (max-width: 876px) {
  .static_pages-offers_enterprise {
    .cards {
      flex-direction: column;
      align-items: center;
    }
  }
}

@media screen and (max-width: 476px) {
  .static_pages-offers_enterprise {
    .hero-text {
      font-size: 32px;
    }
  }
}

.static_pages-offers_enterprise {
  .blocks {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
  }

  .custom-block {
    position: relative;
    width: 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .block-header {
      font-family: 'EB Garamond', serif;
      font-weight: 600;
      font-size: 42px;
      text-align: center;
    }
  }

  .images-grid {
    display: grid;
    grid-template-columns: repeat(2, 300px);
    grid-template-rows: repeat(2, 300px);
    grid-gap: 10px;
    grid-auto-flow: row;
    justify-content: center;


    .grid-item {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      padding: 5px 10px;

      img {
        max-height: fit-content;
        margin: auto 0;
      }

      p {
        font-family: 'Inter', sans-serif;
        font-weight: 600;
        font-size: 20px;
      }
    }

    .custom-item {
      img {
        transform: rotate(-17deg);
        border-radius: 15px;
      }

      .custom-img {
        position: absolute;
        transform: translateY(150%);
      }
    }
  }

  .block-container {
    min-height: 610px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .vertical-separator {
    display: inline-block;
    border-left: 2px solid black;
    margin: 0 10px;
    height: 680px;
  }

  .info-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .text-icon {
    max-width: 65px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-size: 20px;
      color: #1F9AAB;
    }
  }

  .block-icon {
    font-size: 65px;
  }

  .text {
    width: 450px;

    p {
      font-family: 'Inter', sans-serif;
      font-weight: 600;
      font-size: 20px;
    }

    .text-highlight {
      color: #0A4E9B;
    }
  }

  .block-footer {
    font-family: 'EB Garamond', serif;
    font-weight: 500;
    font-size: 22px;
    text-align: center;
    margin-top: 30px;
  }
}

@media screen and (max-width: 1265px) {
  .contact-header {
    font-size: 36px;
    text-align: center;
  }

  .trust {
    .trust-header {
      font-size: 36px;
      text-align: center;
    }
  }

  .static_pages-offers_enterprise {
    .blocks {
      flex-direction: column;
      align-items: center;

      .custom-block {
        width: 75%;

        .block-header {
          margin: 25px 0;
        }

        .info-text {
          margin-top: 10px;
        }
      }
    }

    .vertical-separator {
      display: none;
    }

    .text {
      width: 75%;
    }

    .contact-header,
    .trust-header {
      text-align: center;
    }
  }
}

@media screen and (max-width: 730px) {
  .static_pages-offers_enterprise {

    .text,
    .text-icon {
      p {
        font-size: 18px;
      }
    }

    .contact {
      .picture {
        img {
          width: 260px !important;
          height: 260px !important;
        }
      }
    }
  }
}

@media screen and (max-width: 630px) {
  .static_pages-offers_enterprise {
    .images-grid {
      grid-template-columns: 300px;
      grid-template-rows: repeat(4, 300px);
    }

    .custom-block {
      .block-header {
        font-size: 36px;
      }

      .info-text {
        .text-icon {
          margin-left: 10px;
        }

        p {
          font-size: 14px;
        }
      }
    }

    .contact-header,
    .trust-header {
      font-size: 36px !important;
    }
  }
}

@media screen and (max-width: 488px) {
  .static_pages-offers_enterprise {

    .text,
    .text-icon {
      p {
        font-size: 14px;
      }
    }

    .block-icon {
      font-size: 36px;
    }

    .blocks {
      .custom-block {
        width: 100%;
      }
    }
  }
}

@media screen and (max-width: 420px) {
  .static_pages-offers_enterprise {

    .contact-header,
    .trust-header {
      font-size: 30px !important;
    }
  }

  .contact-header {
    margin-top: 56px;
    font-family: 'EB Garamond', serif;
    font-weight: 600;
    font-size: 48px;
    margin-bottom: 50px;
  }
}

@media screen and (max-width: 970px) {
  .static_pages-offers_enterprise {
    .contact {
      flex-direction: column;
      justify-content: center;

      form {
        margin-top: 50px;
        width: 90%;
      }
    }
    .custom-color {
      background-color: #0EADE9 !important;
    }
  }


  .trust {
    margin-top: 40px;

    .trust-header {
      font-family: 'EB Garamond', serif;
      font-weight: 600;
      font-size: 48px;
      margin-bottom: 40px;
    }

    .trust-grid {
      display: grid;
      grid-template-rows: 1fr;
      grid-auto-flow: row;
      justify-content: center;
      align-items: center;
      grid-gap: 10px;
    }

    .line-1 {
      grid-template-columns: repeat(9, 1fr);
    }

    .line-2 {
      grid-template-columns: repeat(8, 1fr);
    }
  }
}

@media (max-width: 1080px) {
  .contact {
    flex-direction: column;
  }
}
