.btn {
  @apply rounded-md text-sm py-4 px-8 align-middle text-center cursor-pointer;

  &:hover {
    @apply opacity-75;
  }

  &.red {
    @apply bg-red-100 text-red-800 font-bold;
  }

  &.blue {
    @apply bg-blue-100 text-blue-800 font-bold;
  }

  &.brand-blue {
    @apply bg-brand-blue-600 text-white font-bold;
  }

  &.dark-blue {
    @apply py-2 px-4 bg-blue-800 text-white rounded-full;
  }

  &.fat {
    @apply py-8 px-12 text-lg font-bold;
  }
}

.btn-link {
  @apply bg-transparent cursor-pointer;
}

.btn-citc {
  @apply px-3 py-1 md:px-4 md:py-2 border border-transparent shadow text-sm md:text-base font-medium rounded-md cursor-pointer;
  @apply text-white text-center bg-brand-blue-900;
  @apply hover:bg-brand-blue-100;
  @apply focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-brand-blue-700 focus:ring-white;
  
  &.large {
    @apply px-4 py-2 md:px-5 md:py-3 text-base md:text-lg;
  }
  
  &.fat {
    @apply px-5 py-3 md:px-6 md:py-4 text-base md:text-lg;
  }
  
  &.inverted {
    @apply text-brand-blue-900 text-center bg-white border border-brand-blue-900;
    @apply hover:text-white hover:bg-brand-blue-100 hover:border-brand-blue-100;
  }
  
  &.not-allowed, &[disabled] {
    @apply cursor-not-allowed bg-gray-200 hover:bg-gray-200;
  }
  
  &.red {
    @apply bg-red-800 text-white;
  }
  
  &.love {
    background-color: white;
    color: #525252;
  }
  
  &.loved {
    background-color: white;
    color: #D81A5B;

    > svg {
      fill: rgb(219, 13, 13);
    }
  }

  &.twitter {
    background-color: #28aced;
    
    &:hover {
      background-color: white;
      color: #28aced;
    }
  }
  
  &.facebook {
    background-color: #3b5998;
    
    &:hover {
      background-color: white;
      color: #3b5998;
    }
  }
}

.form-btn {
  @apply inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white transition duration-150 ease-in-out;

  &:focus {
    @apply outline-none;
  }

  &.blue {
    @apply bg-brand-blue-600;

    &:hover {
      @apply bg-brand-blue-500;
    }

    &:focus {
      @apply border-brand-blue-700;
    }

    &:active {
      @apply bg-brand-blue-700;
    }
  }

  &.gray {
    @apply bg-gray-600;

    &:hover {
      @apply bg-gray-500;
    }

    &:focus {
      @apply border-gray-700;
    }

    &:active {
      @apply bg-gray-700;
    }
  }
}
