@font-face {
    font-family: 'culturinthecity';
    src:  url('fonts/culturinthecity.eot?7thxne');
    src:  url('fonts/culturinthecity.eot?7thxne#iefix') format('embedded-opentype'),
    url('fonts/culturinthecity.ttf?7thxne') format('truetype'),
    url('fonts/culturinthecity.woff?7thxne') format('woff'),
    url('fonts/culturinthecity.svg?7thxne#culturinthecity') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
    font-family: 'culturinthecity' !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-abacus:before {
    content: "\ebe0";
}
.icon-accessible-icon-alt:before {
    content: "\ebe1";
}
.icon-activity:before {
    content: "\ebe2";
}
.icon-adjust:before {
    content: "\ebe6";
}
.icon-adjust-alt:before {
    content: "\ebe3";
}
.icon-adjust-circle:before {
    content: "\ebe4";
}
.icon-adjust-half:before {
    content: "\ebe5";
}
.icon-adobe:before {
    content: "\ebe8";
}
.icon-adobe-alt:before {
    content: "\ebe7";
}
.icon-airplay:before {
    content: "\ebe9";
}
.icon-align:before {
    content: "\ebf6";
}
.icon-align-alt:before {
    content: "\ebea";
}
.icon-align-center:before {
    content: "\ebef";
}
.icon-align-center-alt:before {
    content: "\ebeb";
}
.icon-align-center-h:before {
    content: "\ebec";
}
.icon-align-center-justify:before {
    content: "\ebed";
}
.icon-align-center-v:before {
    content: "\ebee";
}
.icon-align-justify:before {
    content: "\ebf0";
}
.icon-align-left:before {
    content: "\ebf2";
}
.icon-align-left-justify:before {
    content: "\ebf1";
}
.icon-align-letter-right:before {
    content: "\ebf3";
}
.icon-align-right:before {
    content: "\ebf5";
}
.icon-align-right-justify:before {
    content: "\ebf4";
}
.icon-amazon:before {
    content: "\ebf7";
}
.icon-ambulance:before {
    content: "\ebf8";
}
.icon-analysis:before {
    content: "\ebf9";
}
.icon-analytics:before {
    content: "\ebfa";
}
.icon-anchor:before {
    content: "\ebfb";
}
.icon-android:before {
    content: "\ebfe";
}
.icon-android-alt:before {
    content: "\ebfc";
}
.icon-android-phone-slash:before {
    content: "\ebfd";
}
.icon-angle-double-down:before {
    content: "\ebff";
}
.icon-angle-double-left:before {
    content: "\ec00";
}
.icon-angle-double-right:before {
    content: "\ec01";
}
.icon-angle-double-up:before {
    content: "\ec02";
}
.icon-angle-down:before {
    content: "\ec03";
}
.icon-angle-left:before {
    content: "\ec05";
}
.icon-angle-left-b:before {
    content: "\ec04";
}
.icon-angle-right:before {
    content: "\ec07";
}
.icon-angle-right-b:before {
    content: "\ec06";
}
.icon-angle-up:before {
    content: "\ec08";
}
.icon-angry:before {
    content: "\ec09";
}
.icon-ankh:before {
    content: "\ec0a";
}
.icon-annoyed:before {
    content: "\ec0c";
}
.icon-annoyed-alt:before {
    content: "\ec0b";
}
.icon-apple:before {
    content: "\ec0e";
}
.icon-apple-alt:before {
    content: "\ec0d";
}
.icon-apps:before {
    content: "\ec0f";
}
.icon-archive:before {
    content: "\ec11";
}
.icon-archive-alt:before {
    content: "\ec10";
}
.icon-archway:before {
    content: "\ec12";
}
.icon-arrow:before {
    content: "\ec28";
}
.icon-arrow-break:before {
    content: "\ec13";
}
.icon-arrow-circle-down:before {
    content: "\ec14";
}
.icon-arrow-circle-left:before {
    content: "\ec15";
}
.icon-arrow-circle-right:before {
    content: "\ec16";
}
.icon-arrow-circle-up:before {
    content: "\ec17";
}
.icon-arrow-compress-h:before {
    content: "\ec18";
}
.icon-arrow-down:before {
    content: "\ec1b";
}
.icon-arrow-down-left:before {
    content: "\ec19";
}
.icon-arrow-down-right:before {
    content: "\ec1a";
}
.icon-arrow-from-right:before {
    content: "\ec1c";
}
.icon-arrow-from-top:before {
    content: "\ec1d";
}
.icon-arrow-growth:before {
    content: "\ec1e";
}
.icon-arrow-left:before {
    content: "\ec1f";
}
.icon-arrow-random:before {
    content: "\ec20";
}
.icon-arrow-resize-diagonal:before {
    content: "\ec21";
}
.icon-arrow-right:before {
    content: "\ec22";
}
.icon-arrow-to-bottom:before {
    content: "\ec23";
}
.icon-arrow-to-right:before {
    content: "\ec24";
}
.icon-arrow-up:before {
    content: "\ec27";
}
.icon-arrow-up-left:before {
    content: "\ec25";
}
.icon-arrow-up-right:before {
    content: "\ec26";
}
.icon-arrows-h:before {
    content: "\ec2a";
}
.icon-arrows-h-alt:before {
    content: "\ec29";
}
.icon-arrows-left-down:before {
    content: "\ec2b";
}
.icon-arrows-maximize:before {
    content: "\ec2c";
}
.icon-arrows-merge:before {
    content: "\ec2d";
}
.icon-arrows-resize:before {
    content: "\ec30";
}
.icon-arrows-resize-h:before {
    content: "\ec2e";
}
.icon-arrows-resize-v:before {
    content: "\ec2f";
}
.icon-arrows-right-down:before {
    content: "\ec31";
}
.icon-arrows-shrink-h:before {
    content: "\ec32";
}
.icon-arrows-shrink-v:before {
    content: "\ec33";
}
.icon-arrows-up-right:before {
    content: "\ec34";
}
.icon-arrows-v:before {
    content: "\ec36";
}
.icon-arrows-v-alt:before {
    content: "\ec35";
}
.icon-assistive-listening-systems:before {
    content: "\ec37";
}
.icon-asterisk:before {
    content: "\ec38";
}
.icon-at:before {
    content: "\ec39";
}
.icon-atom:before {
    content: "\ec3a";
}
.icon-auto-flash:before {
    content: "\ec3b";
}
.icon-award:before {
    content: "\ec3d";
}
.icon-award-alt:before {
    content: "\ec3c";
}
.icon-baby-carriage:before {
    content: "\ec3e";
}
.icon-backpack:before {
    content: "\ec3f";
}
.icon-backspace:before {
    content: "\ec40";
}
.icon-backward:before {
    content: "\ec41";
}
.icon-bag:before {
    content: "\ec44";
}
.icon-bag-alt:before {
    content: "\ec42";
}
.icon-bag-slash:before {
    content: "\ec43";
}
.icon-balance-scale:before {
    content: "\ec45";
}
.icon-ban:before {
    content: "\ec46";
}
.icon-band-aid:before {
    content: "\ec47";
}
.icon-bars:before {
    content: "\ec48";
}
.icon-baseball-ball:before {
    content: "\ec49";
}
.icon-basketball:before {
    content: "\ec4b";
}
.icon-basketball-hoop:before {
    content: "\ec4a";
}
.icon-bath:before {
    content: "\ec4c";
}
.icon-battery-bolt:before {
    content: "\ec4d";
}
.icon-battery-empty:before {
    content: "\ec4e";
}
.icon-bed:before {
    content: "\ec50";
}
.icon-bed-double:before {
    content: "\ec4f";
}
.icon-behance:before {
    content: "\ec52";
}
.icon-behance-alt:before {
    content: "\ec51";
}
.icon-bell:before {
    content: "\ec55";
}
.icon-bell-school:before {
    content: "\ec53";
}
.icon-bell-slash:before {
    content: "\ec54";
}
.icon-bill:before {
    content: "\ec56";
}
.icon-bitcoin:before {
    content: "\ec5a";
}
.icon-bitcoin-alt:before {
    content: "\ec57";
}
.icon-bitcoin-circle:before {
    content: "\ec58";
}
.icon-bitcoin-sign:before {
    content: "\ec59";
}
.icon-black-berry:before {
    content: "\ec5b";
}
.icon-blogger:before {
    content: "\ec5d";
}
.icon-blogger-alt:before {
    content: "\ec5c";
}
.icon-bluetooth-b:before {
    content: "\ec5e";
}
.icon-bold:before {
    content: "\ec5f";
}
.icon-bolt:before {
    content: "\ec62";
}
.icon-bolt-alt:before {
    content: "\ec60";
}
.icon-bolt-slash:before {
    content: "\ec61";
}
.icon-book:before {
    content: "\ec67";
}
.icon-book-alt:before {
    content: "\ec63";
}
.icon-book-medical:before {
    content: "\ec64";
}
.icon-book-open:before {
    content: "\ec65";
}
.icon-book-reader:before {
    content: "\ec66";
}
.icon-bookmark:before {
    content: "\ec69";
}
.icon-bookmark-full:before {
    content: "\ec68";
}
.icon-books:before {
    content: "\ec6a";
}
.icon-boombox:before {
    content: "\ec6b";
}
.icon-border-alt:before {
    content: "\ec6c";
}
.icon-border-bottom:before {
    content: "\ec6d";
}
.icon-border-clear:before {
    content: "\ec6e";
}
.icon-border-horizontal:before {
    content: "\ec6f";
}
.icon-border-inner:before {
    content: "\ec70";
}
.icon-border-left:before {
    content: "\ec71";
}
.icon-border-out:before {
    content: "\ec72";
}
.icon-border-right:before {
    content: "\ec73";
}
.icon-border-top:before {
    content: "\ec74";
}
.icon-border-vertical:before {
    content: "\ec75";
}
.icon-bowling-ball:before {
    content: "\ec76";
}
.icon-box:before {
    content: "\ec77";
}
.icon-brackets-curly:before {
    content: "\ec78";
}
.icon-brain:before {
    content: "\ec79";
}
.icon-briefcase:before {
    content: "\ec7b";
}
.icon-briefcase-alt:before {
    content: "\ec7a";
}
.icon-bright:before {
    content: "\ec7c";
}
.icon-brightness:before {
    content: "\ec82";
}
.icon-brightness-empty:before {
    content: "\ec7d";
}
.icon-brightness-half:before {
    content: "\ec7e";
}
.icon-brightness-low:before {
    content: "\ec7f";
}
.icon-brightness-minus:before {
    content: "\ec80";
}
.icon-brightness-plus:before {
    content: "\ec81";
}
.icon-bring-bottom:before {
    content: "\ec83";
}
.icon-bring-front:before {
    content: "\ec84";
}
.icon-browser:before {
    content: "\ec85";
}
.icon-brush-alt:before {
    content: "\ec86";
}
.icon-bug:before {
    content: "\ec87";
}
.icon-building:before {
    content: "\ec88";
}
.icon-bullseye:before {
    content: "\ec89";
}
.icon-bus:before {
    content: "\ec8c";
}
.icon-bus-alt:before {
    content: "\ec8a";
}
.icon-bus-school:before {
    content: "\ec8b";
}
.icon-calculator:before {
    content: "\ec8e";
}
.icon-calculator-alt:before {
    content: "\ec8d";
}
.icon-calendar-alt:before {
    content: "\ec8f";
}
.icon-calendar-slash:before {
    content: "\ec90";
}
.icon-calender:before {
    content: "\ec91";
}
.icon-calling:before {
    content: "\ec92";
}
.icon-camera:before {
    content: "\ec96";
}
.icon-camera-change:before {
    content: "\ec93";
}
.icon-camera-plus:before {
    content: "\ec94";
}
.icon-camera-slash:before {
    content: "\ec95";
}
.icon-cancel:before {
    content: "\ec97";
}
.icon-capsule:before {
    content: "\ec98";
}
.icon-capture:before {
    content: "\ec99";
}
.icon-car:before {
    content: "\ec9d";
}
.icon-car-sideview:before {
    content: "\ec9a";
}
.icon-car-slash:before {
    content: "\ec9b";
}
.icon-car-wash:before {
    content: "\ec9c";
}
.icon-card-atm:before {
    content: "\ec9e";
}
.icon-caret-right:before {
    content: "\ec9f";
}
.icon-cell:before {
    content: "\eca0";
}
.icon-celsius:before {
    content: "\eca1";
}
.icon-channel:before {
    content: "\eca3";
}
.icon-channel-add:before {
    content: "\eca2";
}
.icon-chart:before {
    content: "\ecac";
}
.icon-chart-bar:before {
    content: "\eca5";
}
.icon-chart-bar-alt:before {
    content: "\eca4";
}
.icon-chart-down:before {
    content: "\eca6";
}
.icon-chart-growth:before {
    content: "\eca8";
}
.icon-chart-growth-alt:before {
    content: "\eca7";
}
.icon-chart-line-note:before {
    content: "\eca9";
}
.icon-chart-pie:before {
    content: "\ecab";
}
.icon-chart-pie-alt:before {
    content: "\ecaa";
}
.icon-chat:before {
    content: "\ecaf";
}
.icon-chat-bubble-user:before {
    content: "\ecad";
}
.icon-chat-info:before {
    content: "\ecae";
}
.icon-check:before {
    content: "\ecb6";
}
.icon-check-circle:before {
    content: "\ecb3";
}
.icon-check-circle-1:before {
    content: "\ecb0";
}
.icon-check-circle-empty:before {
    content: "\ecb1";
}
.icon-check-circle-radio:before {
    content: "\ecb2";
}
.icon-check-square:before {
    content: "\ecb5";
}
.icon-check-square-empty:before {
    content: "\ecb4";
}
.icon-circle:before {
    content: "\ecb8";
}
.icon-circle-layer:before {
    content: "\ecb7";
}
.icon-circuit:before {
    content: "\ecb9";
}
.icon-clapper-board:before {
    content: "\ecba";
}
.icon-clipboard:before {
    content: "\ecbe";
}
.icon-clipboard-alt:before {
    content: "\ecbb";
}
.icon-clipboard-blank:before {
    content: "\ecbc";
}
.icon-clipboard-notes:before {
    content: "\ecbd";
}
.icon-clock:before {
    content: "\ecc6";
}
.icon-clock-eight:before {
    content: "\ecbf";
}
.icon-clock-five:before {
    content: "\ecc0";
}
.icon-clock-nine:before {
    content: "\ecc1";
}
.icon-clock-seven:before {
    content: "\ecc2";
}
.icon-clock-ten:before {
    content: "\ecc3";
}
.icon-clock-three:before {
    content: "\ecc4";
}
.icon-clock-two:before {
    content: "\ecc5";
}
.icon-closed-captioning:before {
    content: "\ecc8";
}
.icon-closed-captioning-slash:before {
    content: "\ecc7";
}
.icon-cloud:before {
    content: "\ecf1";
}
.icon-cloud-block:before {
    content: "\ecc9";
}
.icon-cloud-bookmark:before {
    content: "\ecca";
}
.icon-cloud-check:before {
    content: "\eccb";
}
.icon-cloud-computing:before {
    content: "\eccc";
}
.icon-cloud-data-connection:before {
    content: "\eccd";
}
.icon-cloud-database-tree:before {
    content: "\ecce";
}
.icon-cloud-download:before {
    content: "\eccf";
}
.icon-cloud-drizzle:before {
    content: "\ecd0";
}
.icon-cloud-exclamation:before {
    content: "\ecd1";
}
.icon-cloud-hail:before {
    content: "\ecd2";
}
.icon-cloud-heart:before {
    content: "\ecd3";
}
.icon-cloud-info:before {
    content: "\ecd4";
}
.icon-cloud-lock:before {
    content: "\ecd5";
}
.icon-cloud-meatball:before {
    content: "\ecd6";
}
.icon-cloud-moon:before {
    content: "\ecdb";
}
.icon-cloud-moon-hail:before {
    content: "\ecd7";
}
.icon-cloud-moon-meatball:before {
    content: "\ecd8";
}
.icon-cloud-moon-rain:before {
    content: "\ecd9";
}
.icon-cloud-moon-showers:before {
    content: "\ecda";
}
.icon-cloud-question:before {
    content: "\ecdc";
}
.icon-cloud-rain:before {
    content: "\ecde";
}
.icon-cloud-rain-sun:before {
    content: "\ecdd";
}
.icon-cloud-redo:before {
    content: "\ecdf";
}
.icon-cloud-share:before {
    content: "\ece0";
}
.icon-cloud-shield:before {
    content: "\ece1";
}
.icon-cloud-showers:before {
    content: "\ece4";
}
.icon-cloud-showers-alt:before {
    content: "\ece2";
}
.icon-cloud-showers-heavy:before {
    content: "\ece3";
}
.icon-cloud-slash:before {
    content: "\ece5";
}
.icon-cloud-sun:before {
    content: "\eceb";
}
.icon-cloud-sun-hail:before {
    content: "\ece6";
}
.icon-cloud-sun-meatball:before {
    content: "\ece7";
}
.icon-cloud-sun-rain:before {
    content: "\ece9";
}
.icon-cloud-sun-rain-alt:before {
    content: "\ece8";
}
.icon-cloud-sun-tear:before {
    content: "\ecea";
}
.icon-cloud-times:before {
    content: "\ecec";
}
.icon-cloud-unlock:before {
    content: "\eced";
}
.icon-cloud-upload:before {
    content: "\ecee";
}
.icon-cloud-wifi:before {
    content: "\ecef";
}
.icon-cloud-wind:before {
    content: "\ecf0";
}
.icon-clouds:before {
    content: "\ecf2";
}
.icon-club:before {
    content: "\ecf3";
}
.icon-code-branch:before {
    content: "\ecf4";
}
.icon-coffee:before {
    content: "\ecf5";
}
.icon-cog:before {
    content: "\ecf6";
}
.icon-coins:before {
    content: "\ecf7";
}
.icon-columns:before {
    content: "\ecf8";
}
.icon-comment:before {
    content: "\ed2c";
}
.icon-comment-add:before {
    content: "\ecf9";
}
.icon-comment-alt:before {
    content: "\ed12";
}
.icon-comment-alt-block:before {
    content: "\ecfa";
}
.icon-comment-alt-chart-lines:before {
    content: "\ecfb";
}
.icon-comment-alt-check:before {
    content: "\ecfc";
}
.icon-comment-alt-dots:before {
    content: "\ecfd";
}
.icon-comment-alt-download:before {
    content: "\ecfe";
}
.icon-comment-alt-edit:before {
    content: "\ecff";
}
.icon-comment-alt-exclamation:before {
    content: "\ed00";
}
.icon-comment-alt-heart:before {
    content: "\ed01";
}
.icon-comment-alt-image:before {
    content: "\ed02";
}
.icon-comment-alt-info:before {
    content: "\ed03";
}
.icon-comment-alt-lines:before {
    content: "\ed04";
}
.icon-comment-alt-lock:before {
    content: "\ed05";
}
.icon-comment-alt-medical:before {
    content: "\ed06";
}
.icon-comment-alt-message:before {
    content: "\ed07";
}
.icon-comment-alt-notes:before {
    content: "\ed08";
}
.icon-comment-alt-plus:before {
    content: "\ed09";
}
.icon-comment-alt-question:before {
    content: "\ed0a";
}
.icon-comment-alt-redo:before {
    content: "\ed0b";
}
.icon-comment-alt-search:before {
    content: "\ed0c";
}
.icon-comment-alt-share:before {
    content: "\ed0d";
}
.icon-comment-alt-shield:before {
    content: "\ed0e";
}
.icon-comment-alt-slash:before {
    content: "\ed0f";
}
.icon-comment-alt-upload:before {
    content: "\ed10";
}
.icon-comment-alt-verify:before {
    content: "\ed11";
}
.icon-comment-block:before {
    content: "\ed13";
}
.icon-comment-chart-line:before {
    content: "\ed14";
}
.icon-comment-check:before {
    content: "\ed15";
}
.icon-comment-dots:before {
    content: "\ed16";
}
.icon-comment-download:before {
    content: "\ed17";
}
.icon-comment-edit:before {
    content: "\ed18";
}
.icon-comment-exclamation:before {
    content: "\ed19";
}
.icon-comment-heart:before {
    content: "\ed1a";
}
.icon-comment-image:before {
    content: "\ed1b";
}
.icon-comment-info:before {
    content: "\ed1d";
}
.icon-comment-info-alt:before {
    content: "\ed1c";
}
.icon-comment-lines:before {
    content: "\ed1e";
}
.icon-comment-lock:before {
    content: "\ed1f";
}
.icon-comment-medical:before {
    content: "\ed20";
}
.icon-comment-message:before {
    content: "\ed21";
}
.icon-comment-notes:before {
    content: "\ed22";
}
.icon-comment-plus:before {
    content: "\ed23";
}
.icon-comment-question:before {
    content: "\ed24";
}
.icon-comment-redo:before {
    content: "\ed25";
}
.icon-comment-search:before {
    content: "\ed26";
}
.icon-comment-share:before {
    content: "\ed27";
}
.icon-comment-shield:before {
    content: "\ed28";
}
.icon-comment-slash:before {
    content: "\ed29";
}
.icon-comment-upload:before {
    content: "\ed2a";
}
.icon-comment-verify:before {
    content: "\ed2b";
}
.icon-comments:before {
    content: "\ed2e";
}
.icon-comments-alt:before {
    content: "\ed2d";
}
.icon-compact-disc:before {
    content: "\ed2f";
}
.icon-comparison:before {
    content: "\ed30";
}
.icon-compass:before {
    content: "\ed31";
}
.icon-compress:before {
    content: "\ed38";
}
.icon-compress-alt:before {
    content: "\ed33";
}
.icon-compress-alt-left:before {
    content: "\ed32";
}
.icon-compress-arrows:before {
    content: "\ed34";
}
.icon-compress-lines:before {
    content: "\ed35";
}
.icon-compress-point:before {
    content: "\ed36";
}
.icon-compress-v:before {
    content: "\ed37";
}
.icon-confused:before {
    content: "\ed39";
}
.icon-constructor:before {
    content: "\ed3a";
}
.icon-copy:before {
    content: "\ed3d";
}
.icon-copy-alt:before {
    content: "\ed3b";
}
.icon-copy-landscape:before {
    content: "\ed3c";
}
.icon-copyright:before {
    content: "\ed3e";
}
.icon-corner-down-left:before {
    content: "\ed3f";
}
.icon-corner-down-right:before {
    content: "\ed41";
}
.icon-corner-down-right-alt:before {
    content: "\ed40";
}
.icon-corner-left-down:before {
    content: "\ed42";
}
.icon-corner-right-down:before {
    content: "\ed43";
}
.icon-corner-up-left:before {
    content: "\ed45";
}
.icon-corner-up-left-alt:before {
    content: "\ed44";
}
.icon-corner-up-right:before {
    content: "\ed47";
}
.icon-corner-up-right-alt:before {
    content: "\ed46";
}
.icon-create-dashboard:before {
    content: "\ed48";
}
.icon-creative-commons-pd:before {
    content: "\ed49";
}
.icon-credit-card:before {
    content: "\ed4b";
}
.icon-credit-card-search:before {
    content: "\ed4a";
}
.icon-crockery:before {
    content: "\ed4c";
}
.icon-crop-alt:before {
    content: "\ed4f";
}
.icon-crop-alt-rotate-left:before {
    content: "\ed4d";
}
.icon-crop-alt-rotate-right:before {
    content: "\ed4e";
}
.icon-crosshair:before {
    content: "\ed51";
}
.icon-crosshair-alt:before {
    content: "\ed50";
}
.icon-crosshairs:before {
    content: "\ed52";
}
.icon-crown:before {
    content: "\ed53";
}
.icon-cube:before {
    content: "\ed54";
}
.icon-dashboard:before {
    content: "\ed55";
}
.icon-data-sharing:before {
    content: "\ed56";
}
.icon-database:before {
    content: "\ed58";
}
.icon-database-alt:before {
    content: "\ed57";
}
.icon-desert:before {
    content: "\ed59";
}
.icon-desktop:before {
    content: "\ed5e";
}
.icon-desktop-alt:before {
    content: "\ed5b";
}
.icon-desktop-alt-slash:before {
    content: "\ed5a";
}
.icon-desktop-cloud-alt:before {
    content: "\ed5c";
}
.icon-desktop-slash:before {
    content: "\ed5d";
}
.icon-dialpad:before {
    content: "\ed60";
}
.icon-dialpad-alt:before {
    content: "\ed5f";
}
.icon-diamond:before {
    content: "\ed61";
}
.icon-diary:before {
    content: "\ed63";
}
.icon-diary-alt:before {
    content: "\ed62";
}
.icon-dice-five:before {
    content: "\ed64";
}
.icon-dice-four:before {
    content: "\ed65";
}
.icon-dice-one:before {
    content: "\ed66";
}
.icon-dice-six:before {
    content: "\ed67";
}
.icon-dice-three:before {
    content: "\ed68";
}
.icon-dice-two:before {
    content: "\ed69";
}
.icon-direction:before {
    content: "\ed6a";
}
.icon-directions:before {
    content: "\ed6b";
}
.icon-dizzy-meh:before {
    content: "\ed6c";
}
.icon-dna:before {
    content: "\ed6d";
}
.icon-document-info:before {
    content: "\ed6e";
}
.icon-document-layout-center:before {
    content: "\ed6f";
}
.icon-document-layout-left:before {
    content: "\ed70";
}
.icon-document-layout-right:before {
    content: "\ed71";
}
.icon-dollar-alt:before {
    content: "\ed72";
}
.icon-dollar-sign:before {
    content: "\ed74";
}
.icon-dollar-sign-alt:before {
    content: "\ed73";
}
.icon-dots:before {
    content: "\ed75";
}
.icon-download-alt:before {
    content: "\ed76";
}
.icon-dribbble:before {
    content: "\ed77";
}
.icon-drill:before {
    content: "\ed78";
}
.icon-dropbox:before {
    content: "\ed79";
}
.icon-dropdown:before {
    content: "\ed7a";
}
.icon-dropup:before {
    content: "\ed7c";
}
.icon-dumbbell:before {
    content: "\ed7b";
}
.icon-ear:before {
    content: "\e959";
}
.icon-edit:before {
    content: "\e95b";
}
.icon-edit-alt:before {
    content: "\e95a";
}
.icon-ellipsis-h:before {
    content: "\e95c";
}
.icon-ellipsis-v:before {
    content: "\e95d";
}
.icon-emoji:before {
    content: "\e95e";
}
.icon-enter:before {
    content: "\e95f";
}
.icon-envelope:before {
    content: "\e979";
}
.icon-envelope-add:before {
    content: "\e960";
}
.icon-envelope-alt:before {
    content: "\e961";
}
.icon-envelope-block:before {
    content: "\e962";
}
.icon-envelope-bookmark:before {
    content: "\e963";
}
.icon-envelope-check:before {
    content: "\e964";
}
.icon-envelope-download:before {
    content: "\e966";
}
.icon-envelope-download-alt:before {
    content: "\e965";
}
.icon-envelope-edit:before {
    content: "\e967";
}
.icon-envelope-exclamation:before {
    content: "\e968";
}
.icon-envelope-heart:before {
    content: "\e969";
}
.icon-envelope-info:before {
    content: "\e96a";
}
.icon-envelope-lock:before {
    content: "\e96b";
}
.icon-envelope-minus:before {
    content: "\e96c";
}
.icon-envelope-open:before {
    content: "\e96d";
}
.icon-envelope-question:before {
    content: "\e96e";
}
.icon-envelope-receive:before {
    content: "\e96f";
}
.icon-envelope-redo:before {
    content: "\e970";
}
.icon-envelope-search:before {
    content: "\e971";
}
.icon-envelope-send:before {
    content: "\e972";
}
.icon-envelope-share:before {
    content: "\e973";
}
.icon-envelope-shield:before {
    content: "\e974";
}
.icon-envelope-star:before {
    content: "\e975";
}
.icon-envelope-times:before {
    content: "\e976";
}
.icon-envelope-upload:before {
    content: "\e978";
}
.icon-envelope-upload-alt:before {
    content: "\e977";
}
.icon-envelopes:before {
    content: "\e97a";
}
.icon-equal-circle:before {
    content: "\e97b";
}
.icon-euro:before {
    content: "\e97d";
}
.icon-euro-circle:before {
    content: "\e97c";
}
.icon-exchange:before {
    content: "\e97f";
}
.icon-exchange-alt:before {
    content: "\e97e";
}
.icon-exclamation-circle:before {
    content: "\e980";
}
.icon-exclamation-octagon:before {
    content: "\e981";
}
.icon-exclamation-triangle:before {
    content: "\e982";
}
.icon-exclude:before {
    content: "\e983";
}
.icon-exit:before {
    content: "\e984";
}
.icon-expand-alt:before {
    content: "\e985";
}
.icon-expand-arrows:before {
    content: "\e987";
}
.icon-expand-arrows-alt:before {
    content: "\e986";
}
.icon-expand-from-corner:before {
    content: "\e988";
}
.icon-expand-left:before {
    content: "\e989";
}
.icon-expand-right:before {
    content: "\e98a";
}
.icon-export:before {
    content: "\e98b";
}
.icon-exposure-alt:before {
    content: "\e98c";
}
.icon-exposure-increase:before {
    content: "\e98d";
}
.icon-external-link-alt:before {
    content: "\e98e";
}
.icon-eye:before {
    content: "\e990";
}
.icon-eye-slash:before {
    content: "\e98f";
}
.icon-facebook:before {
    content: "\e903";
}
.icon-facebook-f:before {
    content: "\e900";
}
.icon-facebook-messenger:before {
    content: "\e902";
}
.icon-facebook-messenger-alt:before {
    content: "\e901";
}
.icon-fahrenheit:before {
    content: "\e904";
}
.icon-fast-mail:before {
    content: "\e906";
}
.icon-fast-mail-alt:before {
    content: "\e905";
}
.icon-favorite:before {
    content: "\e907";
}
.icon-feedback:before {
    content: "\e908";
}
.icon-file:before {
    content: "\e931";
}
.icon-file-alt:before {
    content: "\e909";
}
.icon-file-avenent:before {
    content: "\e90a";
}
.icon-file-blank:before {
    content: "\e90b";
}
.icon-file-block-alt:before {
    content: "\e90c";
}
.icon-file-bookmark-alt:before {
    content: "\e90d";
}
.icon-file-check:before {
    content: "\e90f";
}
.icon-file-check-alt:before {
    content: "\e90e";
}
.icon-file-contract-dollar:before {
    content: "\e910";
}
.icon-file-copy-alt:before {
    content: "\e911";
}
.icon-file-download:before {
    content: "\e913";
}
.icon-file-download-alt:before {
    content: "\e912";
}
.icon-file-edit-alt:before {
    content: "\e914";
}
.icon-file-exclamation:before {
    content: "\e916";
}
.icon-file-exclamation-alt:before {
    content: "\e915";
}
.icon-file-export:before {
    content: "\e917";
}
.icon-file-heart:before {
    content: "\e918";
}
.icon-file-import:before {
    content: "\e919";
}
.icon-file-info-alt:before {
    content: "\e91a";
}
.icon-file-landscape:before {
    content: "\e91c";
}
.icon-file-landscape-alt:before {
    content: "\e91b";
}
.icon-file-lanscape-slash:before {
    content: "\e91d";
}
.icon-file-lock-alt:before {
    content: "\e91e";
}
.icon-file-medical:before {
    content: "\e920";
}
.icon-file-medical-alt:before {
    content: "\e91f";
}
.icon-file-minus:before {
    content: "\e922";
}
.icon-file-minus-alt:before {
    content: "\e921";
}
.icon-file-network:before {
    content: "\e923";
}
.icon-file-plus:before {
    content: "\e925";
}
.icon-file-plus-alt:before {
    content: "\e924";
}
.icon-file-question:before {
    content: "\e927";
}
.icon-file-question-alt:before {
    content: "\e926";
}
.icon-file-redo-alt:before {
    content: "\e928";
}
.icon-file-search-alt:before {
    content: "\e929";
}
.icon-file-share-alt:before {
    content: "\e92a";
}
.icon-file-shield-alt:before {
    content: "\e92b";
}
.icon-file-slash:before {
    content: "\e92c";
}
.icon-file-times:before {
    content: "\e92e";
}
.icon-file-times-alt:before {
    content: "\e92d";
}
.icon-file-upload:before {
    content: "\e930";
}
.icon-file-upload-alt:before {
    content: "\e92f";
}
.icon-files-landscapes:before {
    content: "\e933";
}
.icon-files-landscapes-alt:before {
    content: "\e932";
}
.icon-film:before {
    content: "\e934";
}
.icon-filter:before {
    content: "\e936";
}
.icon-filter-slash:before {
    content: "\e935";
}
.icon-fire:before {
    content: "\e937";
}
.icon-flask:before {
    content: "\e939";
}
.icon-flask-potion:before {
    content: "\e938";
}
.icon-flip-h:before {
    content: "\e93b";
}
.icon-flip-h-alt:before {
    content: "\e93a";
}
.icon-flip-v:before {
    content: "\e93d";
}
.icon-flip-v-alt:before {
    content: "\e93c";
}
.icon-flower:before {
    content: "\e93e";
}
.icon-focus:before {
    content: "\e941";
}
.icon-focus-add:before {
    content: "\e93f";
}
.icon-focus-target:before {
    content: "\e940";
}
.icon-folder:before {
    content: "\e951";
}
.icon-folder-check:before {
    content: "\e942";
}
.icon-folder-download:before {
    content: "\e943";
}
.icon-folder-exclamation:before {
    content: "\e944";
}
.icon-folder-heart:before {
    content: "\e945";
}
.icon-folder-info:before {
    content: "\e946";
}
.icon-folder-lock:before {
    content: "\e947";
}
.icon-folder-medical:before {
    content: "\e948";
}
.icon-folder-minus:before {
    content: "\e949";
}
.icon-folder-network:before {
    content: "\e94a";
}
.icon-folder-open:before {
    content: "\e94b";
}
.icon-folder-plus:before {
    content: "\e94c";
}
.icon-folder-question:before {
    content: "\e94d";
}
.icon-folder-slash:before {
    content: "\e94e";
}
.icon-folder-times:before {
    content: "\e94f";
}
.icon-folder-upload:before {
    content: "\e950";
}
.icon-football:before {
    content: "\e954";
}
.icon-football-american:before {
    content: "\e952";
}
.icon-football-ball:before {
    content: "\e953";
}
.icon-forecastcloud-moon-tear:before {
    content: "\e955";
}
.icon-forwaded-call:before {
    content: "\e956";
}
.icon-forward:before {
    content: "\e957";
}
.icon-frown:before {
    content: "\e958";
}
.icon-game-structure:before {
    content: "\e991";
}
.icon-gift:before {
    content: "\e992";
}
.icon-github:before {
    content: "\e994";
}
.icon-github-alt:before {
    content: "\e993";
}
.icon-glass:before {
    content: "\e999";
}
.icon-glass-martini:before {
    content: "\e997";
}
.icon-glass-martini-alt:before {
    content: "\e996";
}
.icon-glass-martini-alt-slash:before {
    content: "\e995";
}
.icon-glass-tea:before {
    content: "\e998";
}
.icon-globe:before {
    content: "\e99a";
}
.icon-gold:before {
    content: "\e99b";
}
.icon-golf-ball:before {
    content: "\e99c";
}
.icon-google:before {
    content: "\e9a2";
}
.icon-google-drive:before {
    content: "\e99e";
}
.icon-google-drive-alt:before {
    content: "\e99d";
}
.icon-google-hangouts:before {
    content: "\e9a0";
}
.icon-google-hangouts-alt:before {
    content: "\e99f";
}
.icon-google-play:before {
    content: "\e9a1";
}
.icon-graduation-cap:before {
    content: "\e9a3";
}
.icon-graph-bar:before {
    content: "\e9a4";
}
.icon-grid:before {
    content: "\e9a5";
}
.icon-grids:before {
    content: "\e9a6";
}
.icon-grin:before {
    content: "\e9a9";
}
.icon-grin-tongue-wink:before {
    content: "\e9a8";
}
.icon-grin-tongue-wink-alt:before {
    content: "\e9a7";
}
.icon-grip-horizontal-line:before {
    content: "\e9aa";
}
.icon-hard-hat:before {
    content: "\e9ab";
}
.icon-hdd:before {
    content: "\e9ac";
}
.icon-headphones:before {
    content: "\e9ae";
}
.icon-headphones-alt:before {
    content: "\e9ad";
}
.icon-heart:before {
    content: "\e9b4";
}
.icon-heart-alt:before {
    content: "\e9af";
}
.icon-heart-break:before {
    content: "\e9b0";
}
.icon-heart-medical:before {
    content: "\e9b1";
}
.icon-heart-rate:before {
    content: "\e9b2";
}
.icon-heart-sign:before {
    content: "\e9b3";
}
.icon-heartbeat:before {
    content: "\e9b5";
}
.icon-history:before {
    content: "\e9b7";
}
.icon-history-alt:before {
    content: "\e9b6";
}
.icon-home:before {
    content: "\e9b9";
}
.icon-home-alt:before {
    content: "\e9b8";
}
.icon-horizontal-align-center:before {
    content: "\e9ba";
}
.icon-horizontal-align-left:before {
    content: "\e9bb";
}
.icon-horizontal-align-right:before {
    content: "\e9bc";
}
.icon-horizontal-distribution-center:before {
    content: "\e9bd";
}
.icon-horizontal-distribution-left:before {
    content: "\e9be";
}
.icon-horizontal-distribution-right:before {
    content: "\e9bf";
}
.icon-hourglass:before {
    content: "\e9c0";
}
.icon-html3:before {
    content: "\e9c2";
}
.icon-html3-alt:before {
    content: "\e9c1";
}
.icon-hunting:before {
    content: "\e9c3";
}
.icon-image:before {
    content: "\e9d8";
}
.icon-image-alt-slash:before {
    content: "\e9c4";
}
.icon-image-block:before {
    content: "\e9c5";
}
.icon-image-broken:before {
    content: "\e9c6";
}
.icon-image-check:before {
    content: "\e9c7";
}
.icon-image-download:before {
    content: "\e9c8";
}
.icon-image-edit:before {
    content: "\e9c9";
}
.icon-image-lock:before {
    content: "\e9ca";
}
.icon-image-minus:before {
    content: "\e9cb";
}
.icon-image-plus:before {
    content: "\e9cc";
}
.icon-image-question:before {
    content: "\e9cd";
}
.icon-image-redo:before {
    content: "\e9ce";
}
.icon-image-resize-landscape:before {
    content: "\e9cf";
}
.icon-image-resize-square:before {
    content: "\e9d0";
}
.icon-image-search:before {
    content: "\e9d1";
}
.icon-image-share:before {
    content: "\e9d2";
}
.icon-image-shield:before {
    content: "\e9d3";
}
.icon-image-slash:before {
    content: "\e9d4";
}
.icon-image-times:before {
    content: "\e9d5";
}
.icon-image-upload:before {
    content: "\e9d6";
}
.icon-image-v:before {
    content: "\e9d7";
}
.icon-images:before {
    content: "\e9d9";
}
.icon-import:before {
    content: "\e9da";
}
.icon-incoming-call:before {
    content: "\e9db";
}
.icon-info-circle:before {
    content: "\e9dc";
}
.icon-instagram:before {
    content: "\e9de";
}
.icon-instagram-alt:before {
    content: "\e9dd";
}
.icon-intercom:before {
    content: "\e9e0";
}
.icon-intercom-alt:before {
    content: "\e9df";
}
.icon-invoice:before {
    content: "\e9e1";
}
.icon-italic:before {
    content: "\e9e2";
}
.icon-jackhammer:before {
    content: "\e9e3";
}
.icon-java-script:before {
    content: "\e9e4";
}
.icon-kayak:before {
    content: "\e9e5";
}
.icon-key-skeleton:before {
    content: "\e9e7";
}
.icon-key-skeleton-alt:before {
    content: "\e9e6";
}
.icon-keyboard:before {
    content: "\e9eb";
}
.icon-keyboard-alt:before {
    content: "\e9e8";
}
.icon-keyboard-hide:before {
    content: "\e9e9";
}
.icon-keyboard-show:before {
    content: "\e9ea";
}
.icon-keyhole-circle:before {
    content: "\e9ec";
}
.icon-keyhole-square:before {
    content: "\e9ee";
}
.icon-keyhole-square-full:before {
    content: "\e9ed";
}
.icon-kid:before {
    content: "\e9ef";
}
.icon-label:before {
    content: "\e9f1";
}
.icon-label-alt:before {
    content: "\e9f0";
}
.icon-lamp:before {
    content: "\e9f2";
}
.icon-laptop:before {
    content: "\e9f5";
}
.icon-laptop-cloud:before {
    content: "\e9f3";
}
.icon-laptop-connection:before {
    content: "\e9f4";
}
.icon-laughing:before {
    content: "\e9f6";
}
.icon-layer-group:before {
    content: "\e9f8";
}
.icon-layer-group-slash:before {
    content: "\e9f7";
}
.icon-layers:before {
    content: "\e9fb";
}
.icon-layers-alt:before {
    content: "\e9f9";
}
.icon-layers-slash:before {
    content: "\e9fa";
}
.icon-left-arrow-from-left:before {
    content: "\e9fc";
}
.icon-left-arrow-to-left:before {
    content: "\e9fd";
}
.icon-left-indent:before {
    content: "\e9ff";
}
.icon-left-indent-alt:before {
    content: "\e9fe";
}
.icon-left-to-right-text-direction:before {
    content: "\ea00";
}
.icon-life-ring:before {
    content: "\ea01";
}
.icon-lightbulb:before {
    content: "\ea03";
}
.icon-lightbulb-alt:before {
    content: "\ea02";
}
.icon-line:before {
    content: "\ea06";
}
.icon-line-alt:before {
    content: "\ea04";
}
.icon-line-spacing:before {
    content: "\ea05";
}
.icon-link:before {
    content: "\ea0b";
}
.icon-link-add:before {
    content: "\ea07";
}
.icon-link-alt:before {
    content: "\ea08";
}
.icon-link-broken:before {
    content: "\ea09";
}
.icon-link-h:before {
    content: "\ea0a";
}
.icon-linkedin:before {
    content: "\ea0d";
}
.icon-linkedin-alt:before {
    content: "\ea0c";
}
.icon-list-ui-alt:before {
    content: "\ea0e";
}
.icon-list-ul:before {
    content: "\ea0f";
}
.icon-location-arrow:before {
    content: "\ea11";
}
.icon-location-arrow-alt:before {
    content: "\ea10";
}
.icon-location-pin-alt:before {
    content: "\ea12";
}
.icon-location-point:before {
    content: "\ea13";
}
.icon-lock:before {
    content: "\ea18";
}
.icon-lock-access:before {
    content: "\ea14";
}
.icon-lock-alt:before {
    content: "\ea15";
}
.icon-lock-open-alt:before {
    content: "\ea16";
}
.icon-lock-slash:before {
    content: "\ea17";
}
.icon-luggage-cart:before {
    content: "\ea19";
}
.icon-mailbox:before {
    content: "\ea1b";
}
.icon-mailbox-alt:before {
    content: "\ea1a";
}
.icon-map:before {
    content: "\ea27";
}
.icon-map-marker:before {
    content: "\ea24";
}
.icon-map-marker-alt:before {
    content: "\ea1c";
}
.icon-map-marker-edit:before {
    content: "\ea1d";
}
.icon-map-marker-info:before {
    content: "\ea1e";
}
.icon-map-marker-minus:before {
    content: "\ea1f";
}
.icon-map-marker-plus:before {
    content: "\ea20";
}
.icon-map-marker-question:before {
    content: "\ea21";
}
.icon-map-marker-shield:before {
    content: "\ea22";
}
.icon-map-marker-slash:before {
    content: "\ea23";
}
.icon-map-pin:before {
    content: "\ea26";
}
.icon-map-pin-alt:before {
    content: "\ea25";
}
.icon-mars:before {
    content: "\ea28";
}
.icon-master-card:before {
    content: "\ea29";
}
.icon-maximize-left:before {
    content: "\ea2a";
}
.icon-medal:before {
    content: "\ea2b";
}
.icon-medical-drip:before {
    content: "\ea2c";
}
.icon-medical-square:before {
    content: "\ea2e";
}
.icon-medical-square-full:before {
    content: "\ea2d";
}
.icon-medium-m:before {
    content: "\ea2f";
}
.icon-medkit:before {
    content: "\ea30";
}
.icon-meeting-board:before {
    content: "\ea31";
}
.icon-megaphone:before {
    content: "\ea32";
}
.icon-meh:before {
    content: "\ea35";
}
.icon-meh-alt:before {
    content: "\ea33";
}
.icon-meh-closed-eye:before {
    content: "\ea34";
}
.icon-message:before {
    content: "\ea36";
}
.icon-metro:before {
    content: "\ea37";
}
.icon-microphone:before {
    content: "\ea39";
}
.icon-microphone-slash:before {
    content: "\ea38";
}
.icon-minus:before {
    content: "\ea3e";
}
.icon-minus-circle:before {
    content: "\ea3a";
}
.icon-minus-path:before {
    content: "\ea3b";
}
.icon-minus-square:before {
    content: "\ea3d";
}
.icon-minus-square-full:before {
    content: "\ea3c";
}
.icon-missed-call:before {
    content: "\ea3f";
}
.icon-mobile-android:before {
    content: "\ea41";
}
.icon-mobile-android-alt:before {
    content: "\ea40";
}
.icon-mobile-vibrate:before {
    content: "\ea42";
}
.icon-modem:before {
    content: "\ea43";
}
.icon-money-bill:before {
    content: "\ea46";
}
.icon-money-bill-slash:before {
    content: "\ea44";
}
.icon-money-bill-stack:before {
    content: "\ea45";
}
.icon-money-insert:before {
    content: "\ea47";
}
.icon-money-stack:before {
    content: "\ea48";
}
.icon-money-withdraw:before {
    content: "\ea49";
}
.icon-money-withdrawal:before {
    content: "\ea4a";
}
.icon-moneybag:before {
    content: "\ea4c";
}
.icon-moneybag-alt:before {
    content: "\ea4b";
}
.icon-monitor:before {
    content: "\ea4e";
}
.icon-monitor-heart-rate:before {
    content: "\ea4d";
}
.icon-moon:before {
    content: "\ea50";
}
.icon-moon-eclipse:before {
    content: "\ea4f";
}
.icon-moonset:before {
    content: "\ea51";
}
.icon-mountains:before {
    content: "\ea53";
}
.icon-mountains-sun:before {
    content: "\ea52";
}
.icon-mouse:before {
    content: "\ea56";
}
.icon-mouse-alt:before {
    content: "\ea55";
}
.icon-mouse-alt-2:before {
    content: "\ea54";
}
.icon-multiply:before {
    content: "\ea57";
}
.icon-music:before {
    content: "\ea5a";
}
.icon-music-note:before {
    content: "\ea58";
}
.icon-music-tune-slash:before {
    content: "\ea59";
}
.icon-n-a:before {
    content: "\ea5b";
}
.icon-navigator:before {
    content: "\ea5c";
}
.icon-nerd:before {
    content: "\ea5d";
}
.icon-newspaper:before {
    content: "\ea5e";
}
.icon-ninja:before {
    content: "\ea5f";
}
.icon-no-entry:before {
    content: "\ea60";
}
.icon-notebooks:before {
    content: "\ea61";
}
.icon-notes:before {
    content: "\ea62";
}
.icon-object-group:before {
    content: "\ea63";
}
.icon-object-ungroup:before {
    content: "\ea64";
}
.icon-octagon:before {
    content: "\ea65";
}
.icon-opera:before {
    content: "\ea67";
}
.icon-opera-alt:before {
    content: "\ea66";
}
.icon-outgoing-call:before {
    content: "\ea68";
}
.icon-package:before {
    content: "\ea69";
}
.icon-padlock:before {
    content: "\ea6a";
}
.icon-paint-tool:before {
    content: "\ea6b";
}
.icon-palette:before {
    content: "\ea6c";
}
.icon-panel-add:before {
    content: "\ea6d";
}
.icon-panorama-h:before {
    content: "\ea6f";
}
.icon-panorama-h-alt:before {
    content: "\ea6e";
}
.icon-panorama-v:before {
    content: "\ea70";
}
.icon-paperclip:before {
    content: "\ea71";
}
.icon-paragraph:before {
    content: "\ea72";
}
.icon-parcel:before {
    content: "\ea73";
}
.icon-parking-circle:before {
    content: "\ea74";
}
.icon-parking-square:before {
    content: "\ea75";
}
.icon-pathfinder:before {
    content: "\ea77";
}
.icon-pathfinder-unite:before {
    content: "\ea76";
}
.icon-pause:before {
    content: "\ea79";
}
.icon-pause-circle:before {
    content: "\ea78";
}
.icon-paypal:before {
    content: "\ea7a";
}
.icon-pen:before {
    content: "\ea7b";
}
.icon-pentagon:before {
    content: "\ea7c";
}
.icon-percentage:before {
    content: "\ea7d";
}
.icon-phone:before {
    content: "\ea83";
}
.icon-phone-alt:before {
    content: "\ea7e";
}
.icon-phone-pause:before {
    content: "\ea7f";
}
.icon-phone-slash:before {
    content: "\ea80";
}
.icon-phone-times:before {
    content: "\ea81";
}
.icon-phone-volume:before {
    content: "\ea82";
}
.icon-picture:before {
    content: "\ea84";
}
.icon-pizza-slice:before {
    content: "\ea85";
}
.icon-placeholder:before {
    content: "\ea86";
}
.icon-plane:before {
    content: "\ea8a";
}
.icon-plane-arrival:before {
    content: "\ea87";
}
.icon-plane-departure:before {
    content: "\ea88";
}
.icon-plane-fly:before {
    content: "\ea89";
}
.icon-play:before {
    content: "\ea8d";
}
.icon-play-circle:before {
    content: "\ea8b";
}
.icon-play-full:before {
    content: "\ea8c";
}
.icon-plug:before {
    content: "\ea8e";
}
.icon-plus:before {
    content: "\ea91";
}
.icon-plus-circle:before {
    content: "\ea8f";
}
.icon-plus-square:before {
    content: "\ea90";
}
.icon-plus1:before {
    content: "\ebd6";
}
.icon-plus2:before {
    content: "\ebd7";
}
.icon-plus3:before {
    content: "\ebd8";
}
.icon-plus4:before {
    content: "\ebd9";
}
.icon-plus5:before {
    content: "\ebda";
}
.icon-plus6:before {
    content: "\ebdb";
}
.icon-plus7:before {
    content: "\ebdc";
}
.icon-plus8:before {
    content: "\ebdd";
}
.icon-plus9:before {
    content: "\ebde";
}
.icon-podium:before {
    content: "\ea92";
}
.icon-polygon:before {
    content: "\ea93";
}
.icon-post-stamp:before {
    content: "\ea94";
}
.icon-postcard:before {
    content: "\ea95";
}
.icon-pound:before {
    content: "\ea97";
}
.icon-pound-circle:before {
    content: "\ea96";
}
.icon-power:before {
    content: "\ea98";
}
.icon-prescription-bottle:before {
    content: "\ea99";
}
.icon-presentation:before {
    content: "\eaa2";
}
.icon-presentation-check:before {
    content: "\ea9a";
}
.icon-presentation-edit:before {
    content: "\ea9b";
}
.icon-presentation-line:before {
    content: "\ea9c";
}
.icon-presentation-lines-alt:before {
    content: "\ea9d";
}
.icon-presentation-minus:before {
    content: "\ea9e";
}
.icon-presentation-play:before {
    content: "\ea9f";
}
.icon-presentation-plus:before {
    content: "\eaa0";
}
.icon-presentation-times:before {
    content: "\eaa1";
}
.icon-previous:before {
    content: "\eaa3";
}
.icon-pricetag-alt:before {
    content: "\eaa4";
}
.icon-print:before {
    content: "\eaa6";
}
.icon-print-slash:before {
    content: "\eaa5";
}
.icon-process:before {
    content: "\eaa7";
}
.icon-processor:before {
    content: "\eaa8";
}
.icon-pump:before {
    content: "\eaa9";
}
.icon-puzzle-piece:before {
    content: "\eaaa";
}
.icon-px:before {
    content: "\ebdf";
}
.icon-qrcode-scan:before {
    content: "\eaab";
}
.icon-question-circle:before {
    content: "\eaac";
}
.icon-rainbow:before {
    content: "\eaad";
}
.icon-raindrops:before {
    content: "\eaaf";
}
.icon-raindrops-alt:before {
    content: "\eaae";
}
.icon-react:before {
    content: "\eab0";
}
.icon-receipt:before {
    content: "\eab2";
}
.icon-receipt-alt:before {
    content: "\eab1";
}
.icon-record-audio:before {
    content: "\eab3";
}
.icon-reddit-alien-alt:before {
    content: "\eab4";
}
.icon-redo:before {
    content: "\eab5";
}
.icon-refresh:before {
    content: "\eab6";
}
.icon-registered:before {
    content: "\eab7";
}
.icon-repeat:before {
    content: "\eab8";
}
.icon-restaurant:before {
    content: "\eab9";
}
.icon-right-indent-alt:before {
    content: "\eaba";
}
.icon-right-to-left-text-direction:before {
    content: "\eabb";
}
.icon-robot:before {
    content: "\eabc";
}
.icon-rocket:before {
    content: "\eabd";
}
.icon-rope-way:before {
    content: "\eabe";
}
.icon-rotate-360:before {
    content: "\eabf";
}
.icon-rss:before {
    content: "\eac2";
}
.icon-rss-alt:before {
    content: "\eac0";
}
.icon-rss-interface:before {
    content: "\eac1";
}
.icon-ruler:before {
    content: "\eac4";
}
.icon-ruler-combined:before {
    content: "\eac3";
}
.icon-rupee-sign:before {
    content: "\eac5";
}
.icon-sad:before {
    content: "\eaca";
}
.icon-sad-cry:before {
    content: "\eac6";
}
.icon-sad-crying:before {
    content: "\eac7";
}
.icon-sad-dizzy:before {
    content: "\eac8";
}
.icon-sad-squint:before {
    content: "\eac9";
}
.icon-save:before {
    content: "\eacb";
}
.icon-scaling-left:before {
    content: "\eacc";
}
.icon-scaling-right:before {
    content: "\eacd";
}
.icon-scenery:before {
    content: "\eace";
}
.icon-schedule:before {
    content: "\eacf";
}
.icon-screw:before {
    content: "\ead0";
}
.icon-scroll:before {
    content: "\ead2";
}
.icon-scroll-h:before {
    content: "\ead1";
}
.icon-search:before {
    content: "\ead6";
}
.icon-search-alt:before {
    content: "\ead3";
}
.icon-search-minus:before {
    content: "\ead4";
}
.icon-search-plus:before {
    content: "\ead5";
}
.icon-selfie:before {
    content: "\ead7";
}
.icon-server:before {
    content: "\eadc";
}
.icon-server-alt:before {
    content: "\ead8";
}
.icon-server-connection:before {
    content: "\ead9";
}
.icon-server-network:before {
    content: "\eadb";
}
.icon-server-network-alt:before {
    content: "\eada";
}
.icon-servers:before {
    content: "\eadd";
}
.icon-servicemark:before {
    content: "\eade";
}
.icon-setting:before {
    content: "\eadf";
}
.icon-share-alt:before {
    content: "\eae0";
}
.icon-shield:before {
    content: "\eae5";
}
.icon-shield-check:before {
    content: "\eae1";
}
.icon-shield-exclamation:before {
    content: "\eae2";
}
.icon-shield-question:before {
    content: "\eae3";
}
.icon-shield-slash:before {
    content: "\eae4";
}
.icon-ship:before {
    content: "\eae6";
}
.icon-shop:before {
    content: "\eae7";
}
.icon-shopping-bag:before {
    content: "\eae8";
}
.icon-shopping-basket:before {
    content: "\eae9";
}
.icon-shopping-cart:before {
    content: "\eaeb";
}
.icon-shopping-cart-alt:before {
    content: "\eaea";
}
.icon-shovel:before {
    content: "\eaec";
}
.icon-shrink:before {
    content: "\eaed";
}
.icon-shuffle:before {
    content: "\eaee";
}
.icon-shutter:before {
    content: "\eaf0";
}
.icon-shutter-alt:before {
    content: "\eaef";
}
.icon-sick:before {
    content: "\eaf1";
}
.icon-sigma:before {
    content: "\eaf2";
}
.icon-sign-alt:before {
    content: "\eaf3";
}
.icon-sign-in-alt:before {
    content: "\eaf4";
}
.icon-sign-left:before {
    content: "\eaf5";
}
.icon-sign-out-alt:before {
    content: "\eaf6";
}
.icon-sign-right:before {
    content: "\eaf7";
}
.icon-signal:before {
    content: "\eafa";
}
.icon-signal-alt:before {
    content: "\eaf9";
}
.icon-signal-alt-3:before {
    content: "\eaf8";
}
.icon-signin:before {
    content: "\eafc";
}
.icon-signin-1:before {
    content: "\eafb";
}
.icon-silence:before {
    content: "\eafd";
}
.icon-silent-squint:before {
    content: "\eafe";
}
.icon-sim-card:before {
    content: "\eaff";
}
.icon-sitemap:before {
    content: "\eb00";
}
.icon-skip-forward:before {
    content: "\eb03";
}
.icon-skip-forward-alt:before {
    content: "\eb01";
}
.icon-skip-forward-circle:before {
    content: "\eb02";
}
.icon-skype:before {
    content: "\eb05";
}
.icon-skype-alt:before {
    content: "\eb04";
}
.icon-slack:before {
    content: "\eb07";
}
.icon-slack-alt:before {
    content: "\eb06";
}
.icon-sliders-v:before {
    content: "\eb09";
}
.icon-sliders-v-alt:before {
    content: "\eb08";
}
.icon-smile:before {
    content: "\eb10";
}
.icon-smile-beam:before {
    content: "\eb0a";
}
.icon-smile-dizzy:before {
    content: "\eb0b";
}
.icon-smile-squint-wink:before {
    content: "\eb0d";
}
.icon-smile-squint-wink-alt:before {
    content: "\eb0c";
}
.icon-smile-wink:before {
    content: "\eb0f";
}
.icon-smile-wink-alt:before {
    content: "\eb0e";
}
.icon-snapchat-alt:before {
    content: "\eb11";
}
.icon-snapchat-ghost:before {
    content: "\eb12";
}
.icon-snapchat-square:before {
    content: "\eb13";
}
.icon-snow-flake:before {
    content: "\eb14";
}
.icon-snowflake:before {
    content: "\eb16";
}
.icon-snowflake-alt:before {
    content: "\eb15";
}
.icon-sort:before {
    content: "\eb19";
}
.icon-sort-amount-down:before {
    content: "\eb17";
}
.icon-sort-amount-up:before {
    content: "\eb18";
}
.icon-sorting:before {
    content: "\eb1a";
}
.icon-space-key:before {
    content: "\eb1b";
}
.icon-spade:before {
    content: "\eb1c";
}
.icon-speed-1x:before {
    content: "\eb1d";
}
.icon-sperms:before {
    content: "\eb1e";
}
.icon-spin:before {
    content: "\eb1f";
}
.icon-square:before {
    content: "\eb22";
}
.icon-square-full:before {
    content: "\eb20";
}
.icon-square-shape:before {
    content: "\eb21";
}
.icon-squint:before {
    content: "\eb23";
}
.icon-star:before {
    content: "\eb27";
}
.icon-star-filled:before {
    content: "\eb24";
}
.icon-star-half-alt:before {
    content: "\eb25";
}
.icon-star-half-filled:before {
    content: "\eb26";
}
.icon-step-backward:before {
    content: "\eb2a";
}
.icon-step-backward-alt:before {
    content: "\eb28";
}
.icon-step-backward-circle:before {
    content: "\eb29";
}
.icon-step-forward:before {
    content: "\eb2b";
}
.icon-stop-circle:before {
    content: "\eb2c";
}
.icon-stopwatch:before {
    content: "\eb2e";
}
.icon-stopwatch-slash:before {
    content: "\eb2d";
}
.icon-store:before {
    content: "\eb30";
}
.icon-store-alt:before {
    content: "\eb2f";
}
.icon-streering:before {
    content: "\eb31";
}
.icon-stretcher:before {
    content: "\eb32";
}
.icon-subject:before {
    content: "\eb33";
}
.icon-subway:before {
    content: "\eb35";
}
.icon-subway-alt:before {
    content: "\eb34";
}
.icon-suitcase:before {
    content: "\eb37";
}
.icon-suitcase-alt:before {
    content: "\eb36";
}
.icon-sun:before {
    content: "\eb38";
}
.icon-sunset:before {
    content: "\eb39";
}
.icon-surprise:before {
    content: "\eb3a";
}
.icon-swatchbook:before {
    content: "\eb3b";
}
.icon-swiggy:before {
    content: "\eb3c";
}
.icon-swimmer:before {
    content: "\eb3d";
}
.icon-sync:before {
    content: "\eb40";
}
.icon-sync-exclamation:before {
    content: "\eb3e";
}
.icon-sync-slash:before {
    content: "\eb3f";
}
.icon-syringe:before {
    content: "\eb41";
}
.icon-table:before {
    content: "\eb43";
}
.icon-table-tennis:before {
    content: "\eb42";
}
.icon-tablet:before {
    content: "\eb44";
}
.icon-tablets:before {
    content: "\eb45";
}
.icon-tachometer-fast:before {
    content: "\eb47";
}
.icon-tachometer-fast-alt:before {
    content: "\eb46";
}
.icon-tag:before {
    content: "\eb49";
}
.icon-tag-alt:before {
    content: "\eb48";
}
.icon-tape:before {
    content: "\eb4a";
}
.icon-taxi:before {
    content: "\eb4b";
}
.icon-tear:before {
    content: "\eb4c";
}
.icon-telegram:before {
    content: "\eb4e";
}
.icon-telegram-alt:before {
    content: "\eb4d";
}
.icon-telescope:before {
    content: "\eb4f";
}
.icon-temperature:before {
    content: "\eb56";
}
.icon-temperature-empty:before {
    content: "\eb50";
}
.icon-temperature-half:before {
    content: "\eb51";
}
.icon-temperature-minus:before {
    content: "\eb52";
}
.icon-temperature-plus:before {
    content: "\eb53";
}
.icon-temperature-quarter:before {
    content: "\eb54";
}
.icon-temperature-three-quarter:before {
    content: "\eb55";
}
.icon-tennis-ball:before {
    content: "\eb57";
}
.icon-text:before {
    content: "\eb5b";
}
.icon-text-fields:before {
    content: "\eb58";
}
.icon-text-size:before {
    content: "\eb59";
}
.icon-text-strike-through:before {
    content: "\eb5a";
}
.icon-th:before {
    content: "\eb5e";
}
.icon-th-large:before {
    content: "\eb5c";
}
.icon-th-slash:before {
    content: "\eb5d";
}
.icon-thermometer:before {
    content: "\eb5f";
}
.icon-thumbs-down:before {
    content: "\eb60";
}
.icon-thumbs-up:before {
    content: "\eb61";
}
.icon-thunderstorm:before {
    content: "\eb64";
}
.icon-thunderstorm-moon:before {
    content: "\eb62";
}
.icon-thunderstorm-sun:before {
    content: "\eb63";
}
.icon-ticket:before {
    content: "\eb65";
}
.icon-times:before {
    content: "\eb68";
}
.icon-times-circle:before {
    content: "\eb66";
}
.icon-times-square:before {
    content: "\eb67";
}
.icon-toggle-off:before {
    content: "\eb69";
}
.icon-toggle-on:before {
    content: "\eb6a";
}
.icon-top-arrow-from-top:before {
    content: "\eb6b";
}
.icon-top-arrow-to-top:before {
    content: "\eb6c";
}
.icon-tornado:before {
    content: "\eb6d";
}
.icon-trademark:before {
    content: "\eb6f";
}
.icon-trademark-circle:before {
    content: "\eb6e";
}
.icon-traffic-barrier:before {
    content: "\eb70";
}
.icon-traffic-light:before {
    content: "\eb71";
}
.icon-transaction:before {
    content: "\eb72";
}
.icon-trash:before {
    content: "\eb74";
}
.icon-trash-alt:before {
    content: "\eb73";
}
.icon-trees:before {
    content: "\eb75";
}
.icon-triangle:before {
    content: "\eb76";
}
.icon-trophy:before {
    content: "\eb77";
}
.icon-trowel:before {
    content: "\eb78";
}
.icon-truck:before {
    content: "\eb7a";
}
.icon-truck-loading:before {
    content: "\eb79";
}
.icon-tumblr:before {
    content: "\eb7d";
}
.icon-tumblr-alt:before {
    content: "\eb7b";
}
.icon-tumblr-square:before {
    content: "\eb7c";
}
.icon-tv-retro:before {
    content: "\eb7f";
}
.icon-tv-retro-slash:before {
    content: "\eb7e";
}
.icon-twitter:before {
    content: "\eb81";
}
.icon-twitter-alt:before {
    content: "\eb80";
}
.icon-umbrella:before {
    content: "\eb82";
}
.icon-unamused:before {
    content: "\eb83";
}
.icon-underline:before {
    content: "\eb84";
}
.icon-university:before {
    content: "\eb85";
}
.icon-unlock:before {
    content: "\eb87";
}
.icon-unlock-alt:before {
    content: "\eb86";
}
.icon-upload:before {
    content: "\eb89";
}
.icon-upload-alt:before {
    content: "\eb88";
}
.icon-usd-circle:before {
    content: "\eb8a";
}
.icon-usd-square:before {
    content: "\eb8b";
}
.icon-user:before {
    content: "\eb94";
}
.icon-user-check:before {
    content: "\eb8c";
}
.icon-user-circle:before {
    content: "\eb8d";
}
.icon-user-exclamation:before {
    content: "\eb8e";
}
.icon-user-location:before {
    content: "\eb8f";
}
.icon-user-minus:before {
    content: "\eb90";
}
.icon-user-plus:before {
    content: "\eb91";
}
.icon-user-square:before {
    content: "\eb92";
}
.icon-user-times:before {
    content: "\eb93";
}
.icon-users-alt:before {
    content: "\eb95";
}
.icon-utensils:before {
    content: "\eb97";
}
.icon-utensils-alt:before {
    content: "\eb96";
}
.icon-vector-square:before {
    content: "\eb99";
}
.icon-vector-square-alt:before {
    content: "\eb98";
}
.icon-venus:before {
    content: "\eb9a";
}
.icon-vertical-align-bottom:before {
    content: "\eb9b";
}
.icon-vertical-align-center:before {
    content: "\eb9c";
}
.icon-vertical-align-top:before {
    content: "\eb9d";
}
.icon-vertical-distribute-bottom:before {
    content: "\eb9e";
}
.icon-vertical-distribution-center:before {
    content: "\eb9f";
}
.icon-vertical-distribution-top:before {
    content: "\eba0";
}
.icon-video:before {
    content: "\eba2";
}
.icon-video-slash:before {
    content: "\eba1";
}
.icon-visual-studio:before {
    content: "\eba3";
}
.icon-vk:before {
    content: "\eba5";
}
.icon-vk-alt:before {
    content: "\eba4";
}
.icon-voicemail:before {
    content: "\eba7";
}
.icon-voicemail-rectangle:before {
    content: "\eba6";
}
.icon-volleyball:before {
    content: "\eba8";
}
.icon-volume:before {
    content: "\ebad";
}
.icon-volume-down:before {
    content: "\eba9";
}
.icon-volume-mute:before {
    content: "\ebaa";
}
.icon-volume-off:before {
    content: "\ebab";
}
.icon-volume-up:before {
    content: "\ebac";
}
.icon-vuejs:before {
    content: "\ebaf";
}
.icon-vuejs-alt:before {
    content: "\ebae";
}
.icon-wall:before {
    content: "\ebb0";
}
.icon-wallet:before {
    content: "\ebb1";
}
.icon-watch:before {
    content: "\ebb3";
}
.icon-watch-alt:before {
    content: "\ebb2";
}
.icon-water:before {
    content: "\ebb6";
}
.icon-water-drop-slash:before {
    content: "\ebb4";
}
.icon-water-glass:before {
    content: "\ebb5";
}
.icon-web-grid:before {
    content: "\ebb8";
}
.icon-web-grid-alt:before {
    content: "\ebb7";
}
.icon-web-section:before {
    content: "\ebba";
}
.icon-web-section-alt:before {
    content: "\ebb9";
}
.icon-webcam:before {
    content: "\ebbb";
}
.icon-weight:before {
    content: "\ebbc";
}
.icon-whatsapp:before {
    content: "\ebbe";
}
.icon-whatsapp-alt:before {
    content: "\ebbd";
}
.icon-wheel-barrow:before {
    content: "\ebbf";
}
.icon-wheelchair:before {
    content: "\ebc1";
}
.icon-wheelchair-alt:before {
    content: "\ebc0";
}
.icon-wifi:before {
    content: "\ebc4";
}
.icon-wifi-router:before {
    content: "\ebc2";
}
.icon-wifi-slash:before {
    content: "\ebc3";
}
.icon-wind:before {
    content: "\ebc7";
}
.icon-wind-moon:before {
    content: "\ebc5";
}
.icon-wind-sun:before {
    content: "\ebc6";
}
.icon-window:before {
    content: "\ebcb";
}
.icon-window-grid:before {
    content: "\ebc8";
}
.icon-window-maximize:before {
    content: "\ebc9";
}
.icon-window-section:before {
    content: "\ebca";
}
.icon-windsock:before {
    content: "\ebcc";
}
.icon-windy:before {
    content: "\ebcd";
}
.icon-wrap-text:before {
    content: "\ebce";
}
.icon-wrench:before {
    content: "\ebcf";
}
.icon-x:before {
    content: "\ebd1";
}
.icon-x-add:before {
    content: "\ebd0";
}
.icon-yen:before {
    content: "\ebd3";
}
.icon-yen-circle:before {
    content: "\ebd2";
}
.icon-yin-yang:before {
    content: "\ebd4";
}
.icon-youtube:before {
    content: "\ebd5";
}
