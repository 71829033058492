.hero-title {
  text-shadow: rgb(0 0 0 / 80%) 2px 2px 5px;
}

/*
 * Choices
 */

.choices[data-type*="select-one"] .choices__button:focus {
  box-shadow: 0px 0px 0px 2px #e9ecef;
}

.choices__list--multiple .choices__item {
  border-radius: 3px;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 40, 100, 0.12);
  color: #495057;
}

.choices__list--multiple .choices__item.is-highlighted {
  background-color: rgba(0, 40, 100, 0.12);
  border: 1px solid rgba(0, 40, 100, 0.12);
}

.choices[data-type*="select-multiple"] .choices__button,
.choices[data-type*="text"] .choices__button {
  border-left: 1px solid rgba(0, 40, 100, 0.3);
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAyMSAyMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSIjMzAzMDMwIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiPjxwYXRoIGQ9Ik0yLjU5Mi4wNDRsMTguMzY0IDE4LjM2NC0yLjU0OCAyLjU0OEwuMDQ0IDIuNTkyeiIvPjxwYXRoIGQ9Ik0wIDE4LjM2NEwxOC4zNjQgMGwyLjU0OCAyLjU0OEwyLjU0OCAyMC45MTJ6Ii8+PC9nPjwvc3ZnPg==);
}

/*
 * Calendar
 */
.simple-calendar {
  .day-cell {
    width: 14.2857%;
    @apply whitespace-nowrap text-sm font-medium text-gray-900 border border-gray-200;
    
    &.past {
      @apply bg-gray-200;
    }
  }
}

/*
 * Reviews
 */
 
.review-bar {
  background-color: #dcf0f9;
  border-radius: 1rem;
  height: 12px;
  position: relative;
  width: 67%;

  .full-bar {
    background-color: #05acee;
    border-radius: 1rem;
    height: 12px;
    position: absolute;
    left: 0;
  }
}