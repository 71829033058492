/*! purgecss start ignore */
@import "choices.js/public/assets/styles/choices";
@import "@tarekraafat/autocomplete.js/dist/css/autoComplete.01";
@import "flatpickr";
@import "flatpickr/dist/themes/light";
@import "mapbox-gl";
@import "tippy.js/dist/tippy";

$flag-icons-path: 'flag-icons/flags';
@import "flag-icons/sass/flag-icons";
/*! purgecss end ignore */